import React, { useState, useCallback, useEffect, Suspense } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import LoadingSpinner from './shared/components/UIElements/LoadingSpinner'
import Home from './shared/components/Navigation/Home.js'
import Info from './shared/components/Navigation/Info.js'
import Teams from './teams/pages/Teams.js'
import RosterAndSchedulePage from './players/components/RosterAndSchedulePage.js'
import Leaders from './leaders/pages/Leaders.js'
import StatsAndStandingsHome from './shared/components/Navigation/StatsAndStandingsHome.js'
import SchedulesHome from './shared/components/Navigation/SchedulesHome.js'
import Standings from './standings/pages/Standings.js'
import AdminPage from './admin/pages/AdminPage.js'
import { AuthContext } from './shared/context/auth-context'
import LoginPage from './admin/pages/LoginPage.js'
import AdminTeamList from './admin/components/AdminTeamList.js'
import AdminVenueList from './admin/components/AdminVenueList.js'
import AdminVideoList from './admin/components/AdminVideoList.js'
import AdminNewsList from './admin/components/AdminNewsList.js'
import AdminSuspendedPlayers from './admin/components/AdminSuspendedPlayers.js'
import AdminOrginizations from './admin/components/AdminOrganizations.js'
import AdminNewSuspension from './admin/components/AdminNewSuspension.js'
import AdminNewOrg from './admin/components/AdminNewOrg.js'
import AdminArchivedTeams from './admin/components/AdminArchivedTeams.js'
import AdminRegisteredTeams from './admin/components/AdminRegisteredTeams.js'
import AdminPlayersOnTeamList from './admin/components/AdminPlayersOnTeamList.js'
import AdminArchivedLeagueList from './admin/components/AdminArchivedLeagueList.js'
import AdminUpdateSuspension from './admin/components/AdminUpdateSuspension.js'
import AdminUpdateOrg from './admin/components/AdminUpdateOrg.js'
import PlayerHistory from './players/components/PlayerHistory.js'
import TeamHistoryHome from './players/components/TeamHistoryHome'
import AdminCoachCodeOfConductList from './admin/components/AdminCoachCodeOfConductList.js'
import AdminMasterSchedule from './admin/components/AdminMasterSchedule.js'
import LeagueSchedule from './shared/schedule/LeagueSchedule.js'
import CodeOfConduct from './shared/schedule/CodeOfConduct.js'
import TeamRegistration from './shared/schedule/TeamRegistration.js'
import RegisterAnotherTeam from './shared/schedule/RegisterAnotherTeam.js'
import TeamRegistrationUpdate from './shared/schedule/TeamRegistrationUpdate.js'
import TeamRegistrationHome from './shared/components/Navigation/TeamRegistrationHome.js'
import TeamRegistrationUpdateHome from './shared/components/Navigation/TeamRegistrationUpdateHome.js'
import RegisterAnotherTeamHome from './shared/components/Navigation/RegisterAnotherTeamHome.js'
import VenueSchedule from './shared/schedule/VenueSchedule'
import AdminGameResults from './admin/components/AdminGameResults.js'
import AdminGameResultsPlayoff from './admin/components/AdminGameResultsPlayoff.js'
import AdminGameResultsChampionship from './admin/components/AdminGameResultsChampionship'
import TeamSchedulesHome from './shared/components/Navigation/TeamSchedulesHome'
import AdminVenueSchedule from './admin/components/AdminVenueSchedule'
import AdminLeagueSchedule from './admin/components/AdminLeagueSchedule'
import PreviousLeagues from './league/pages/PreviousLeagues'
import GameSummaryHome from './shared/components/Navigation/GameSummaryHome'
import RegisterHome from './registration/pages/RegisterHome'
import AdminRegistrationListOfNames from './admin/components/AdminRegistrationListOfNames'
import PlayerPhotoForm from './players/pages/PlayerPhotoForm'
import NonAdminRegisteredTeams from './players/components/NonAdminRegisteredTeams.js'
//
// lazy loading...
const NewTeamForm = React.lazy(() => import('./teams/pages/NewTeamForm.js'))
const NewLeagueForm = React.lazy(() =>
	import('./league/pages/NewLeagueForm.js')
)
const NewVenueForm = React.lazy(() =>
	import('./shared/schedule/NewVenueForm.js')
)
const NewVideoForm = React.lazy(() =>
	import('./shared/schedule/NewVideoForm.js')
)
const NewNewsForm = React.lazy(() => import('./shared/schedule/NewNewsForm.js'))
const NewTournamentForm = React.lazy(() =>
	import('./registration/pages/NewTournamentForm')
)
const NewPlayerOnTeamForm = React.lazy(() =>
	import('./players/pages/NewPlayerOnTeamForm.js')
)
const UpdateLeagueForm = React.lazy(() =>
	import('./league/pages/UpdateLeagueForm.js')
)
const UpdateVenueForm = React.lazy(() =>
	import('./shared/schedule/UpdateVenueForm.js')
)
const UpdateRegistrantForm = React.lazy(() =>
	import('./registration/pages/UpdateRegistrantForm')
)
const UpdateVideoForm = React.lazy(() =>
	import('./shared/schedule/UpdateVideoForm.js')
)
const UpdateNewsForm = React.lazy(() =>
	import('./shared/schedule/UpdateNewsForm.js')
)
const UpdateTournamentForm = React.lazy(() =>
	import('./registration/pages/UpdateTournamentForm')
)
const UpdateTeamForm = React.lazy(() =>
	import('./teams/pages/UpdateTeamForm.js')
)
const UpdatePlayerForm = React.lazy(() =>
	import('./players/pages/UpdatePlayerForm.js')
)
const AddGamesForm = React.lazy(() => import('./admin/games/AddGamesForm.js'))
const BrandNewPlayerForm = React.lazy(() =>
	import('./players/pages/BrandNewPlayerForm.js')
)
const ChangePlayerNumberForm = React.lazy(() =>
	import('./players/pages/ChangePlayerNumberForm.js')
)
const AddEventsForm = React.lazy(() => import('./admin/games/AddEventsForm.js'))
const UpdateGameForm = React.lazy(() =>
	import('./admin/games/UpdateGameForm.js')
)
const UpdateEventForm = React.lazy(() =>
	import('./admin/games/UpdateEventForm.js')
)

let logoutTimer

function App() {
	//Matt:  all this authorization logic stuff, Max ended up putting in a different
	//file called auth-hook.js.  This was optional.  I'm not doing that.  I'm just
	//keeping all that logic in here.
	const [isLoggedIn, setIsLoggedIn] = useState(false)
	const [token, setToken] = useState()
	const [tokenExpirationDate, setTokenExpirationDate] = useState()
	const [coachIsLoggedIn, setCoachIsLoggedIn] = useState(false)
	//const [coachId, setCoachId] = useState()
	const [coachToken, setCoachToken] = useState()
	const [coachTokenExpirationDate, setCoachTokenExpirationDate] = useState()

	//let navigate = useNavigate()

	//We use useCallback here so that it is not re-created unnecessarily to
	//avoid infinite loops.
	//Due to scope, the tokenExpirationDate below is NOT the same one as the useState above.
	//localStorage can only store text, not objects, so we have to stringify the object.
	//new Date gets CURRENT date, gets the time, then adds an hour to it.
	//One thousand milliseconds = 1 second.  Times 60 for one minute.  Times 60 for one hour.
	//We send the expiration date to the useState, and we add it to the local storage.
	const login = useCallback((token, expirationDate) => {
		console.log('inside regular login in App.js')
		setIsLoggedIn(true)
		setToken(token)
		const tokenExpirationDate =
			expirationDate || new Date(new Date().getTime() + 5000 * 60 * 60) //1000*60*60
		setTokenExpirationDate(tokenExpirationDate)
		localStorage.setItem(
			'userData',
			JSON.stringify({
				token: token,
				expiration: tokenExpirationDate.toISOString(),
			})
		)
	}, [])
	//
	// TEST:  lets duplicate this for coach and see if it works...
	//
	const coachLogin = useCallback((coachToken, coachExpirationDate) => {
		//console.log('inside coachLogin in App.js ' + coachId)
		//setCoachId(coachId)
		setCoachIsLoggedIn(true)
		setCoachToken(coachToken)
		const coachTokenExpirationDate =
			coachExpirationDate || new Date(new Date().getTime() + 1000 * 60 * 60) //1000*60*60
		setCoachTokenExpirationDate(coachTokenExpirationDate)
		localStorage.setItem(
			'coachUserData',
			JSON.stringify({
				coachToken: coachToken,
				coachExpiration: coachTokenExpirationDate.toISOString(),
				//id: coachId,
			})
		)
		//localStorage.setItem('id', coachId)
	}, [])
	//
	//
	const setCoachId = useCallback((coachId) => {
		//console.log('inside setCoachId in App.js ' + coachId)
		localStorage.setItem('id', coachId)
	}, [])
	//
	//
	//Notice that when a user logs out, we want to totally remove the
	//user data, which would include the token.  This ensures that if we
	//logout, we will stay logged out if we reload.
	//Make sure to wipe out the token expiration date upon logging out.
	const logout = useCallback(() => {
		//console.log('inside logout in App.js')
		setIsLoggedIn(false)
		setToken(null)
		setTokenExpirationDate(null)
		localStorage.removeItem('userData')
	}, [])
	//
	//  duplicate for coach
	//
	const coachLogout = useCallback(() => {
		setCoachIsLoggedIn(false)
		setCoachToken(null)
		setCoachTokenExpirationDate(null)
		localStorage.removeItem('coachUserData')
		localStorage.removeItem('id')
	}, [])

	//We want to run some logic here whenever this component renders for the
	//first time, so we'll use useEffect.
	//This function will act as a timer that will log the user out automatically
	//once their expiration time is reached.
	//Token is a dependency.  If the token changes, then we want to work with
	//our timer.  Now, the token either changed because we logged in, or it
	//changed because we logged out.
	//So...if we got a token, and we got a token expiration date, then we want
	//to set our timer.  So we need to calculate the remaining time, which is
	//the expiration time minus the current time.
	//If we have no token or no expiration time then we want to clear our timer
	//because maybe we have no token anymore because we clicked on logout.
	//Essentially, we log out and clear the timer, we log in and set a NEW timer.
	useEffect(() => {
		if (token && tokenExpirationDate) {
			const remainingTime = tokenExpirationDate.getTime() - new Date().getTime()
			logoutTimer = setTimeout(logout, remainingTime)
		} else {
			//if we have NO token or no tokenExpirationDate
			clearTimeout(logoutTimer)
		}
	}, [token, logout, tokenExpirationDate])
	//
	//
	// duplicate for coach
	//
	//
	useEffect(() => {
		//console.log('inside some random useEffect')
		if (coachToken && coachTokenExpirationDate) {
			const remainingTime =
				coachTokenExpirationDate.getTime() - new Date().getTime()
			logoutTimer = setTimeout(coachLogout, remainingTime)
		} else {
			//if we have NO token or no tokenExpirationDate
			clearTimeout(logoutTimer)
		}
	}, [coachToken, coachLogout, coachTokenExpirationDate])
	//
	//
	//We want to run some logic here whenever this component renders for the
	//first time, so we'll use useEffect.  This function will check localStorage
	//for a token. We extract userData, which is text, then we parse it to turn
	//it into a JSON object.
	//Then we check to see if A: we have storedData, and B: that we have a token
	//in there.  If so, we can run our login function and forward our userId, token,
	//and expiration date.  This makes the login function a dependency here, but
	//thanks to the useCallback on the login function, this will only run once and
	//it will not rerun thereafter.  SIDE NOTE:  this function has to come AFTER
	//the login function because the login function is a dependency.
	//We also check the expiration date, which we grab out of local storage.  If it's
	//GREATER than the current time, it's (the token's) still valid.
	useEffect(() => {
		const storedData = JSON.parse(localStorage.getItem('userData'))
		if (
			storedData &&
			storedData.token &&
			new Date(storedData.expiration) > new Date()
		) {
			login(storedData.token, new Date(storedData.expiration))
		}
	}, [login])
	//
	//
	//
	useEffect(() => {
		const coachStoredData = JSON.parse(localStorage.getItem('coachUserData'))
		if (
			coachStoredData &&
			coachStoredData.coachToken &&
			new Date(coachStoredData.coachExpiration) > new Date()
		) {
			coachLogin(
				coachStoredData.coachToken,
				new Date(coachStoredData.coachExpiration)
			)
		}
	}, [coachLogin])
	//
	//
	useEffect(() => {
		const coachStoredId = localStorage.getItem('id')
		if (coachStoredId) {
			setCoachId(coachStoredId)
		}
	}, [setCoachId])
	//
	//
	let routes
	//
	//
	if (isLoggedIn) {
		console.log('ADMIN is logged in')
	}
	if (coachIsLoggedIn) {
		console.log('looks like a COACH is logged in')
	}
	//
	if (isLoggedIn) {
		routes = (
			<React.Fragment>
				<Routes>
					<Route exact path='/' element={<Home />}></Route>
					<Route
						exact
						path='/admin/team/current'
						element={<AdminPage />}
					></Route>
					<Route
						exact
						path='/statsandstandings'
						element={<RosterAndSchedulePage />}
					></Route>
					<Route
						exact
						path='/gameSummary/:gameId'
						element={<GameSummaryHome />}
					></Route>
					<Route exact path='/conduct' element={<SchedulesHome />}></Route>
					<Route
						exact
						path='/rosters'
						element={<NonAdminRegisteredTeams />}
					></Route>
					<Route exact path='/info' element={<Info />}></Route>
					<Route
						exact
						path='/teamRegistration/:coachId'
						element={<TeamRegistrationHome />}
					></Route>
					<Route
						exact
						path='/admin/updateRegisteredTeam/:coachId/:team/:year'
						element={<TeamRegistrationUpdateHome />}
					></Route>
					<Route
						exact
						path='/admin/registerAnotherTeam/:coachId'
						element={<RegisterAnotherTeamHome />}
					></Route>
					<Route exact path='/league/:leagueId' element={<Teams />}></Route>
					<Route
						exact
						path='/league/:leagueId/scoringLeaders'
						element={<Leaders />}
					></Route>
					<Route exact path='/history' element={<TeamHistoryHome />}></Route>
					<Route
						exact
						path='/league/:leagueId/standings'
						element={<Standings />}
					></Route>
					<Route
						exact
						path='/league/previousLeagues'
						element={<PreviousLeagues />}
					></Route>
					<Route
						exact
						path='/league/schedule/:leagueId'
						element={<LeagueSchedule />}
					></Route>
					<Route
						exact
						path='/admin/:venueId/schedule'
						element={<AdminVenueSchedule />}
					></Route>
					<Route
						exact
						path='/admin/:playerId/playerPhoto'
						element={<PlayerPhotoForm />}
					></Route>
					<Route
						exact
						path='/schedule/admin/:leagueId'
						element={<AdminLeagueSchedule />}
					></Route>
					<Route
						exact
						path='/league/:venueId/schedule'
						element={<VenueSchedule />}
					></Route>
					<Route
						exact
						path='/league/schedule/:leagueName/:session/:year/:teamName'
						element={<TeamSchedulesHome />}
					></Route>
					<Route
						exact
						path='/admin/team/new'
						element={<NewLeagueForm />}
					></Route>
					<Route
						exact
						path='/admin/venue/new'
						element={<NewVenueForm />}
					></Route>
					<Route
						exact
						path='/admin/video/new'
						element={<NewVideoForm />}
					></Route>
					<Route exact path='/admin/news/new' element={<NewNewsForm />}></Route>
					<Route
						exact
						path='/register/tournament/new'
						element={<NewTournamentForm />}
					></Route>
					<Route
						exact
						path='/admin/teams/archive'
						element={<AdminArchivedLeagueList />}
					></Route>
					<Route
						exact
						path='/admin/updateTeam/:teamId'
						element={<UpdateLeagueForm />}
					></Route>
					<Route
						exact
						path='/admin/updateVenue/:venueId'
						element={<UpdateVenueForm />}
					></Route>
					<Route
						exact
						path='/admin/updateVideo/:videoId'
						element={<UpdateVideoForm />}
					></Route>
					<Route
						exact
						path='/admin/updateNews/:newsId'
						element={<UpdateNewsForm />}
					></Route>
					<Route
						exact
						path='/admin/updateRegistrant/:modalFor/:registrantId'
						element={<UpdateRegistrantForm />}
					></Route>
					<Route
						exact
						path='/registration/updateTournament/:tournamentId'
						element={<UpdateTournamentForm />}
					></Route>
					<Route
						exact
						path='/admin/:leagueName/:session/:year/newTeam'
						element={<NewTeamForm />}
					></Route>
					<Route
						exact
						path='/admin/:teamName/:year/newPlayer'
						element={<NewPlayerOnTeamForm />}
					></Route>
					<Route
						exact
						path='/admin/:leagueName/:session/:year/updateTeam/:teamId'
						element={<UpdateTeamForm />}
					></Route>
					<Route
						exact
						path='/admin/:leagueName/:session/:year/updateTeamWithDivision/:teamId'
						element={<UpdateTeamForm />}
					></Route>
					<Route
						exact
						path='/admin/:teamName/:year/:playerId/changeNumber'
						element={<ChangePlayerNumberForm />}
					></Route>
					<Route
						exact
						path='/admin/:teamName/:year/roster'
						element={<AdminTeamList />}
					></Route>
					<Route
						exact
						path='/admin/:leagueName/:divisionName/:session/:year/teams'
						element={<AdminTeamList />}
					></Route>
					<Route
						exact
						path='/admin/masterSchedule'
						element={<AdminMasterSchedule />}
					></Route>
					<Route
						exact
						path='/admin/venues'
						element={<AdminVenueList />}
					></Route>
					<Route
						exact
						path='/admin/videos'
						element={<AdminVideoList />}
					></Route>
					<Route exact path='/admin/news' element={<AdminNewsList />}></Route>
					<Route
						exact
						path='/admin/archived'
						element={<AdminArchivedTeams />}
					></Route>
					<Route
						exact
						path='/admin/registration'
						element={<AdminRegisteredTeams />}
					></Route>
					<Route
						exact
						path='/admin/:teamName/:year/players'
						element={<AdminPlayersOnTeamList />}
					></Route>
					<Route
						exact
						path='/admin/:leagueName/:divisionName/:session/:year/:teamName/players'
						element={<AdminPlayersOnTeamList />}
					></Route>
					<Route
						exact
						path='/admin/newPlayer'
						element={<BrandNewPlayerForm />}
					></Route>
					<Route
						exact
						path='/admin/getAllCOCCoaches'
						element={<AdminCoachCodeOfConductList />}
					></Route>
					<Route
						exact
						path='/admin/updatePlayer/:playerId'
						element={<UpdatePlayerForm />}
					></Route>
					<Route
						exact
						path='/admin/updateGame/:gameId'
						element={<UpdateGameForm />}
					></Route>
					<Route
						exact
						path='/admin/updateEvent/:gameId'
						element={<UpdateEventForm />}
					></Route>
					<Route
						exact
						path='/admin/:teamName/:year/addPlayers'
						element={<NewPlayerOnTeamForm />}
					></Route>
					<Route exact path='/games/new' element={<AddGamesForm />}></Route>
					<Route
						exact
						path='/admin/events/new'
						element={<AddEventsForm />}
					></Route>
					<Route
						exact
						path='/admin/gameResults/:gameId'
						element={<AdminGameResults />}
					></Route>
					<Route
						exact
						path='/admin/playoffGameResults/:gameId'
						element={<AdminGameResultsPlayoff />}
					></Route>
					<Route
						exact
						path='/admin/championshipGameResults/:gameId'
						element={<AdminGameResultsChampionship />}
					></Route>
					<Route
						exact
						path='/admin/suspended'
						element={<AdminSuspendedPlayers />}
					></Route>
					<Route
						exact
						path='/admin/orgs'
						element={<AdminOrginizations />}
					></Route>
					<Route
						exact
						path='/admin/:suspensionId/updateSuspension'
						element={<AdminUpdateSuspension />}
					></Route>
					<Route
						exact
						path='/admin/:orgId/updateOrg'
						element={<AdminUpdateOrg />}
					></Route>
					<Route
						exact
						path='/admin/suspension/new'
						element={<AdminNewSuspension />}
					></Route>
					<Route exact path='/admin/org/new' element={<AdminNewOrg />}></Route>
					<Route
						exact
						path='/admin/:leagueNameAndDesc'
						element={<AdminRegistrationListOfNames />}
					></Route>
					<Route
						exact
						path='/:playerId/history'
						element={<PlayerHistory />}
					></Route>
					<Route exact path='/register' element={<RegisterHome />}></Route>
				</Routes>
			</React.Fragment>
		)
		//
		//
		//  If user is non-admin, BUT they're a coach
		//  We want them to see all normal people stuff, PLUS the team registration page
		//
	} else if (!isLoggedIn && coachIsLoggedIn) {
		routes = (
			<React.Fragment>
				<Routes>
					<Route exact path='/' element={<Home />}></Route>
					<Route exact path='/admin/login' element={<LoginPage />}></Route>
					<Route
						exact
						path='/statsandstandings'
						element={<RosterAndSchedulePage />}
					></Route>
					<Route exact path='/history' element={<TeamHistoryHome />}></Route>
					<Route
						exact
						path='/:playerId/history'
						element={<PlayerHistory />}
					></Route>
					<Route
						exact
						path='/gameSummary/:gameId'
						element={<GameSummaryHome />}
					></Route>
					<Route exact path='/conduct' element={<SchedulesHome />}></Route>
					<Route
						exact
						path='/rosters'
						element={<NonAdminRegisteredTeams />}
					></Route>
					<Route exact path='/info' element={<Info />}></Route>
					<Route exact path='/league/:leagueId' element={<Teams />}></Route>
					<Route
						exact
						path='/league/previousLeagues'
						element={<PreviousLeagues />}
					></Route>
					<Route
						exact
						path='/league/:leagueId/scoringLeaders'
						element={<Leaders />}
					></Route>
					<Route
						exact
						path='/league/leagues/:leagueId/standings'
						element={<Standings />}
					></Route>
					<Route
						exact
						path='/league/schedule/:leagueId'
						element={<LeagueSchedule />}
					></Route>
					<Route
						exact
						path='/league/:venueId/schedule'
						element={<VenueSchedule />}
					></Route>
					<Route
						exact
						path='/teamRegistration/:coachId'
						element={<TeamRegistrationHome />}
					></Route>
					<Route
						exact
						path='/admin/updateRegisteredTeam/:coachId/:team/:year'
						element={<TeamRegistrationUpdateHome />}
					></Route>
					<Route
						exact
						path='/admin/registerAnotherTeam/:coachId'
						element={<RegisterAnotherTeamHome />}
					></Route>
					<Route
						exact
						path='/league/schedule/:leagueName/:session/:year/:teamName'
						element={<CodeOfConduct />}
					></Route>
					<Route exact path='/register' element={<RegisterHome />}></Route>
				</Routes>
			</React.Fragment>
		)
		//
		// Else, if they're not admin AND theyre not a coach,
		//   this is what theyll see
		//
		//
	} else {
		routes = (
			<React.Fragment>
				<Routes>
					<Route exact path='/' element={<Home />}></Route>
					<Route exact path='/admin/login' element={<LoginPage />}></Route>
					<Route
						exact
						path='/statsandstandings'
						element={<RosterAndSchedulePage />}
					></Route>
					<Route exact path='/history' element={<TeamHistoryHome />}></Route>
					<Route
						exact
						path='/:playerId/history'
						element={<PlayerHistory />}
					></Route>
					<Route
						exact
						path='/gameSummary/:gameId'
						element={<GameSummaryHome />}
					></Route>
					<Route exact path='/conduct' element={<SchedulesHome />}></Route>
					<Route
						exact
						path='/rosters'
						element={<NonAdminRegisteredTeams />}
					></Route>
					<Route exact path='/info' element={<Info />}></Route>
					<Route exact path='/league/:leagueId' element={<Teams />}></Route>
					<Route
						exact
						path='/league/previousLeagues'
						element={<PreviousLeagues />}
					></Route>
					<Route
						exact
						path='/league/:leagueId/scoringLeaders'
						element={<Leaders />}
					></Route>
					<Route
						exact
						path='/league/leagues/:leagueId/standings'
						element={<Standings />}
					></Route>
					<Route
						exact
						path='/league/schedule/:leagueId'
						element={<LeagueSchedule />}
					></Route>
					<Route
						exact
						path='/league/:venueId/schedule'
						element={<VenueSchedule />}
					></Route>
					<Route
						exact
						path='/league/schedule/:leagueName/:session/:year/:teamName'
						element={<CodeOfConduct />}
					></Route>
					<Route exact path='/register' element={<RegisterHome />}></Route>
				</Routes>
			</React.Fragment>
		)
	}

	//not sure if I want to include a redirect here?  Watch end of Max video 40 if i decide to
	return (
		<AuthContext.Provider
			value={{
				isLoggedIn: !!token,
				coachIsLoggedIn: !!coachToken,
				token: token,
				coachToken: coachToken,
				login: login,
				logout: logout,
				coachLogin: coachLogin,
				setCoachId: setCoachId,
				coachLogout: coachLogout,
			}}
		>
			<Router>
				<Suspense
					fallback={
						<div className='center'>
							<LoadingSpinner />
						</div>
					}
				>
					{routes}
				</Suspense>
			</Router>
		</AuthContext.Provider>
	)
}

export default App
