//This is the Admin Registration Setup Page.  It is used by admins to
//set up the type of league, event, or activity that users will register for.
//Whatever is added to this page will show up as an option for the users
//on the 'Register' page.
import React, { useEffect, useState } from 'react'
import './AdminRegistration.css'
import { NavLink, Link, useNavigate } from 'react-router-dom'
import emailjs from 'emailjs-com'
import AdminButton from '../../shared/components/UIElements/AdminButton'
import AdminNavigation from './AdminNavigation'
import ErrorModal from '../../shared/components/UIElements/ErrorModal'
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner'
import ModalEmail from '../../shared/components/UIElements/ModalEmail'
import Modal from '../../shared/components/UIElements/Modal'
import { useHttpClient } from '../../shared/hooks/http-hook'

const AdminRegisteredTeams = () => {
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState()
	const [loadedTeams, setLoadedTeams] = useState()
	const [year, setYear] = useState()
	const [emailModal, setEmailModal] = useState()
	const [rosterModal, setRosterModal] = useState()
	const [filteredLoadedTeams, setFilteredLoadedTeams] = useState()
	const [emailAddresses, setEmailAddresses] = useState()
	//const [filteredByYear, setFilteredByYear] = useState()
	const [teamName, setTeamName] = useState()
	const [dateOfLastEdit, setDateOfLastEdit] = useState()
	const [teamId, setTeamId] = useState()
	const [showConfirmMoveToArchiveModal, setShowConfirmMoveToArchiveModal] =
		useState(false)
	const [teamToArchive, setTeamToArchive] = useState()
	const { sendRequest, clearError } = useHttpClient()
	//
	const [name1, setName1] = useState()
	const [number1, setNumber1] = useState()
	const [BY1, setBY1] = useState()
	const [isGoalie1, setIsGoalie1] = useState()
	const [isTaxi1, setIsTaxi1] = useState()
	//
	const [name2, setName2] = useState()
	const [number2, setNumber2] = useState()
	const [BY2, setBY2] = useState()
	const [isGoalie2, setIsGoalie2] = useState()
	const [isTaxi2, setIsTaxi2] = useState()
	//
	const [name3, setName3] = useState()
	const [number3, setNumber3] = useState()
	const [BY3, setBY3] = useState()
	const [isGoalie3, setIsGoalie3] = useState()
	const [isTaxi3, setIsTaxi3] = useState()
	//
	const [name4, setName4] = useState()
	const [number4, setNumber4] = useState()
	const [BY4, setBY4] = useState()
	const [isGoalie4, setIsGoalie4] = useState()
	const [isTaxi4, setIsTaxi4] = useState()
	//
	const [name5, setName5] = useState()
	const [number5, setNumber5] = useState()
	const [BY5, setBY5] = useState()
	const [isGoalie5, setIsGoalie5] = useState()
	const [isTaxi5, setIsTaxi5] = useState()
	//
	const [name6, setName6] = useState()
	const [number6, setNumber6] = useState()
	const [BY6, setBY6] = useState()
	const [isGoalie6, setIsGoalie6] = useState()
	const [isTaxi6, setIsTaxi6] = useState()
	//
	const [name7, setName7] = useState()
	const [number7, setNumber7] = useState()
	const [BY7, setBY7] = useState()
	const [isGoalie7, setIsGoalie7] = useState()
	const [isTaxi7, setIsTaxi7] = useState()
	//
	const [name8, setName8] = useState()
	const [number8, setNumber8] = useState()
	const [BY8, setBY8] = useState()
	const [isGoalie8, setIsGoalie8] = useState()
	const [isTaxi8, setIsTaxi8] = useState()
	//
	const [name9, setName9] = useState()
	const [number9, setNumber9] = useState()
	const [BY9, setBY9] = useState()
	const [isGoalie9, setIsGoalie9] = useState()
	const [isTaxi9, setIsTaxi9] = useState()
	//
	const [name10, setName10] = useState()
	const [number10, setNumber10] = useState()
	const [BY10, setBY10] = useState()
	const [isGoalie10, setIsGoalie10] = useState()
	const [isTaxi10, setIsTaxi10] = useState()
	//
	const [name11, setName11] = useState()
	const [number11, setNumber11] = useState()
	const [BY11, setBY11] = useState()
	const [isGoalie11, setIsGoalie11] = useState()
	const [isTaxi11, setIsTaxi11] = useState()
	//
	const [name12, setName12] = useState()
	const [number12, setNumber12] = useState()
	const [BY12, setBY12] = useState()
	const [isGoalie12, setIsGoalie12] = useState()
	const [isTaxi12, setIsTaxi12] = useState()
	//
	const [name13, setName13] = useState()
	const [number13, setNumber13] = useState()
	const [BY13, setBY13] = useState()
	const [isGoalie13, setIsGoalie13] = useState()
	const [isTaxi13, setIsTaxi13] = useState()
	//
	const [name14, setName14] = useState()
	const [number14, setNumber14] = useState()
	const [BY14, setBY14] = useState()
	const [isGoalie14, setIsGoalie14] = useState()
	const [isTaxi14, setIsTaxi14] = useState()
	//
	const [name15, setName15] = useState()
	const [number15, setNumber15] = useState()
	const [BY15, setBY15] = useState()
	const [isGoalie15, setIsGoalie15] = useState()
	const [isTaxi15, setIsTaxi15] = useState()
	//
	const [name16, setName16] = useState()
	const [number16, setNumber16] = useState()
	const [BY16, setBY16] = useState()
	const [isGoalie16, setIsGoalie16] = useState()
	const [isTaxi16, setIsTaxi16] = useState()
	//
	const [name17, setName17] = useState()
	const [number17, setNumber17] = useState()
	const [BY17, setBY17] = useState()
	const [isGoalie17, setIsGoalie17] = useState()
	const [isTaxi17, setIsTaxi17] = useState()
	//
	const [name18, setName18] = useState()
	const [number18, setNumber18] = useState()
	const [BY18, setBY18] = useState()
	const [isGoalie18, setIsGoalie18] = useState()
	const [isTaxi18, setIsTaxi18] = useState()
	//
	const [name19, setName19] = useState()
	const [number19, setNumber19] = useState()
	const [BY19, setBY19] = useState()
	const [isGoalie19, setIsGoalie19] = useState()
	const [isTaxi19, setIsTaxi19] = useState()
	//
	const [name20, setName20] = useState()
	const [number20, setNumber20] = useState()
	const [BY20, setBY20] = useState()
	const [isGoalie20, setIsGoalie20] = useState()
	const [isTaxi20, setIsTaxi20] = useState()
	//
	const [name21, setName21] = useState()
	const [number21, setNumber21] = useState()
	const [BY21, setBY21] = useState()
	const [isGoalie21, setIsGoalie21] = useState()
	const [isTaxi21, setIsTaxi21] = useState()
	//
	const [name22, setName22] = useState()
	const [number22, setNumber22] = useState()
	const [BY22, setBY22] = useState()
	const [isGoalie22, setIsGoalie22] = useState()
	const [isTaxi22, setIsTaxi22] = useState()
	//
	const [name23, setName23] = useState()
	const [number23, setNumber23] = useState()
	const [BY23, setBY23] = useState()
	const [isGoalie23, setIsGoalie23] = useState()
	const [isTaxi23, setIsTaxi23] = useState()
	//
	const [name24, setName24] = useState()
	const [number24, setNumber24] = useState()
	const [BY24, setBY24] = useState()
	const [isGoalie24, setIsGoalie24] = useState()
	const [isTaxi24, setIsTaxi24] = useState()
	//
	const [name25, setName25] = useState()
	const [number25, setNumber25] = useState()
	const [BY25, setBY25] = useState()
	const [isGoalie25, setIsGoalie25] = useState()
	const [isTaxi25, setIsTaxi25] = useState()
	//
	const [name26, setName26] = useState()
	const [number26, setNumber26] = useState()
	const [BY26, setBY26] = useState()
	const [isGoalie26, setIsGoalie26] = useState()
	const [isTaxi26, setIsTaxi26] = useState()
	//
	const [name27, setName27] = useState()
	const [number27, setNumber27] = useState()
	const [BY27, setBY27] = useState()
	const [isGoalie27, setIsGoalie27] = useState()
	const [isTaxi27, setIsTaxi27] = useState()
	//
	const [name28, setName28] = useState()
	const [number28, setNumber28] = useState()
	const [BY28, setBY28] = useState()
	const [isGoalie28, setIsGoalie28] = useState()
	const [isTaxi28, setIsTaxi28] = useState()
	//
	const [name29, setName29] = useState()
	const [number29, setNumber29] = useState()
	const [BY29, setBY29] = useState()
	const [isGoalie29, setIsGoalie29] = useState()
	const [isTaxi29, setIsTaxi29] = useState()
	//
	const [name30, setName30] = useState()
	const [number30, setNumber30] = useState()
	const [BY30, setBY30] = useState()
	const [isGoalie30, setIsGoalie30] = useState()
	const [isTaxi30, setIsTaxi30] = useState()
	//
	const [isActive1, setIsActive1] = useState()
	const [isActive2, setIsActive2] = useState()
	const [isActive3, setIsActive3] = useState()
	const [isActive4, setIsActive4] = useState()
	const [isActive5, setIsActive5] = useState()
	const [isActive6, setIsActive6] = useState()
	const [isActive7, setIsActive7] = useState()
	const [isActive8, setIsActive8] = useState()
	const [isActive9, setIsActive9] = useState()
	const [isActive10, setIsActive10] = useState()
	const [isActive11, setIsActive11] = useState()
	const [isActive12, setIsActive12] = useState()
	const [isActive13, setIsActive13] = useState()
	const [isActive14, setIsActive14] = useState()
	const [isActive15, setIsActive15] = useState()
	const [isActive16, setIsActive16] = useState()
	const [isActive17, setIsActive17] = useState()
	const [isActive18, setIsActive18] = useState()
	const [isActive19, setIsActive19] = useState()
	const [isActive20, setIsActive20] = useState()
	const [isActive21, setIsActive21] = useState()
	const [isActive22, setIsActive22] = useState()
	const [isActive23, setIsActive23] = useState()
	const [isActive24, setIsActive24] = useState()
	const [isActive25, setIsActive25] = useState()
	const [isActive26, setIsActive26] = useState()
	const [isActive27, setIsActive27] = useState()
	const [isActive28, setIsActive28] = useState()
	const [isActive29, setIsActive29] = useState()
	const [isActive30, setIsActive30] = useState()
	//First, lets tap into the following route on the backend to get all
	//the registered teams
	useEffect(() => {
		const sendRequest = async () => {
			setIsLoading(true)
			try {
				const response = await fetch(
					process.env.REACT_APP_BACKEND_URL + `/registration/allRegisteredTeams`
				)

				const responseData = await response.json()

				if (!response.ok) {
					throw new Error(responseData.message)
				}

				setLoadedTeams(responseData.allRegisteredTeams)
				setEmailAddresses(responseData.allEmailAddresses)
				setIsLoading(false)
			} catch (err) {
				setError(err.message)
			}
			setTeamToArchive(false)
			setIsLoading(false)
		}
		sendRequest()
	}, [teamToArchive])
	//
	//
	console.log('loadedTeams: ' + JSON.stringify(loadedTeams))
	//
	//
	let emailAddressesArray, uniqueEmailAddresses
	emailAddressesArray = []
	if (emailAddresses) {
		emailAddresses.forEach((emailAddress) => {
			emailAddressesArray.push(emailAddress)
		})
	}
	//
	//
	if (emailAddressesArray.length > 0) {
		uniqueEmailAddresses = emailAddressesArray.filter(
			(value, index) => emailAddressesArray.indexOf(value) === index
		)
	}
	//
	//
	let navigate = useNavigate()
	//
	//
	const errorHandler = () => {
		setError(null)
	}
	//
	//console.log('loadedTeams: ' + JSON.stringify(loadedTeams))
	//
	//
	let teamsFilteredByYear
	teamsFilteredByYear = []
	const handleFilterByYear = (e) => {
		e.preventDefault()
		const selectedYear = e.target.value
		setYear(selectedYear)

		loadedTeams.forEach((team) => {
			if (team.year === Number(selectedYear)) {
				teamsFilteredByYear.push(team)
			}
		})
		setFilteredLoadedTeams(teamsFilteredByYear)
	}
	//
	//
	//
	//
	const showEmailModal = (e) => {
		const value = e.target.id
		setEmailModal(true)
	}
	//
	//
	const cancelEmailHandler = () => {
		setEmailModal(false)
	}
	//
	//
	//
	function sendEmail(e) {
		e.preventDefault()

		emailjs
			.sendForm(
				'service_qeuvtpo',
				'template_htf42za',
				document.getElementById('emailForm'),
				'GFXJpgnQXBXpF8Y3w'
			)
			.then((res) => {
				console.log(res)
				alert('Your email is on its way!')
				setEmailModal(false)
			})
			.catch((err) => console.log(err))
	}
	//
	//
	//
	//
	const showRosterModal = (e, key) => {
		const value = e.target.id
		console.log('roster for: ' + key)
		setTeamName(loadedTeams[key].team)
		setDateOfLastEdit(loadedTeams[key].date)
		//
		setName1(loadedTeams[key].name1)
		setNumber1(loadedTeams[key].number1)
		setBY1(loadedTeams[key].BY1)
		setIsGoalie1(loadedTeams[key].playerIsGoalie1)
		setIsTaxi1(loadedTeams[key].playerIsTaxi1)
		//
		setName2(loadedTeams[key].name2)
		setNumber2(loadedTeams[key].number2)
		setBY2(loadedTeams[key].BY2)
		setIsGoalie2(loadedTeams[key].playerIsGoalie2)
		setIsTaxi2(loadedTeams[key].playerIsTaxi2)
		//
		setName3(loadedTeams[key].name3)
		setNumber3(loadedTeams[key].number3)
		setBY3(loadedTeams[key].BY3)
		setIsGoalie3(loadedTeams[key].playerIsGoalie3)
		setIsTaxi3(loadedTeams[key].playerIsTaxi3)
		//
		setName4(loadedTeams[key].name4)
		setNumber4(loadedTeams[key].number4)
		setBY4(loadedTeams[key].BY4)
		setIsGoalie4(loadedTeams[key].playerIsGoalie4)
		setIsTaxi4(loadedTeams[key].playerIsTaxi4)
		//
		setName5(loadedTeams[key].name5)
		setNumber5(loadedTeams[key].number5)
		setBY5(loadedTeams[key].BY5)
		setIsGoalie5(loadedTeams[key].playerIsGoalie5)
		setIsTaxi5(loadedTeams[key].playerIsTaxi5)
		//
		setName6(loadedTeams[key].name6)
		setNumber6(loadedTeams[key].number6)
		setBY6(loadedTeams[key].BY6)
		setIsGoalie6(loadedTeams[key].playerIsGoalie6)
		setIsTaxi6(loadedTeams[key].playerIsTaxi6)
		//
		setName7(loadedTeams[key].name7)
		setNumber7(loadedTeams[key].number7)
		setBY7(loadedTeams[key].BY7)
		setIsGoalie7(loadedTeams[key].playerIsGoalie7)
		setIsTaxi7(loadedTeams[key].playerIsTaxi7)
		//
		setName8(loadedTeams[key].name8)
		setNumber8(loadedTeams[key].number8)
		setBY8(loadedTeams[key].BY8)
		setIsGoalie8(loadedTeams[key].playerIsGoalie8)
		setIsTaxi8(loadedTeams[key].playerIsTaxi8)
		//
		setName9(loadedTeams[key].name9)
		setNumber9(loadedTeams[key].number9)
		setBY9(loadedTeams[key].BY9)
		setIsGoalie9(loadedTeams[key].playerIsGoalie9)
		setIsTaxi9(loadedTeams[key].playerIsTaxi9)
		//
		setName10(loadedTeams[key].name10)
		setNumber10(loadedTeams[key].number10)
		setBY10(loadedTeams[key].BY10)
		setIsGoalie10(loadedTeams[key].playerIsGoalie10)
		setIsTaxi10(loadedTeams[key].playerIsTaxi10)
		//
		setName11(loadedTeams[key].name11)
		setNumber11(loadedTeams[key].number11)
		setBY11(loadedTeams[key].BY11)
		setIsGoalie11(loadedTeams[key].playerIsGoalie11)
		setIsTaxi11(loadedTeams[key].playerIsTaxi11)
		//
		setName12(loadedTeams[key].name12)
		setNumber12(loadedTeams[key].number12)
		setBY12(loadedTeams[key].BY12)
		setIsGoalie12(loadedTeams[key].playerIsGoalie12)
		setIsTaxi12(loadedTeams[key].playerIsTaxi12)
		//
		setName13(loadedTeams[key].name13)
		setNumber13(loadedTeams[key].number13)
		setBY13(loadedTeams[key].BY13)
		setIsGoalie13(loadedTeams[key].playerIsGoalie13)
		setIsTaxi13(loadedTeams[key].playerIsTaxi13)
		//
		setName14(loadedTeams[key].name14)
		setNumber14(loadedTeams[key].number14)
		setBY14(loadedTeams[key].BY14)
		setIsGoalie14(loadedTeams[key].playerIsGoalie14)
		setIsTaxi14(loadedTeams[key].playerIsTaxi14)
		//
		setName15(loadedTeams[key].name15)
		setNumber15(loadedTeams[key].number15)
		setBY15(loadedTeams[key].BY15)
		setIsGoalie15(loadedTeams[key].playerIsGoalie15)
		setIsTaxi15(loadedTeams[key].playerIsTaxi15)
		//
		setName16(loadedTeams[key].name16)
		setNumber16(loadedTeams[key].number16)
		setBY16(loadedTeams[key].BY16)
		setIsGoalie16(loadedTeams[key].playerIsGoalie16)
		setIsTaxi16(loadedTeams[key].playerIsTaxi16)
		//
		setName17(loadedTeams[key].name17)
		setNumber17(loadedTeams[key].number17)
		setBY17(loadedTeams[key].BY17)
		setIsGoalie17(loadedTeams[key].playerIsGoalie17)
		setIsTaxi17(loadedTeams[key].playerIsTaxi17)
		//
		setName18(loadedTeams[key].name18)
		setNumber18(loadedTeams[key].number18)
		setBY18(loadedTeams[key].BY18)
		setIsGoalie18(loadedTeams[key].playerIsGoalie18)
		setIsTaxi18(loadedTeams[key].playerIsTaxi18)
		//
		setName19(loadedTeams[key].name19)
		setNumber19(loadedTeams[key].number19)
		setBY19(loadedTeams[key].BY19)
		setIsGoalie19(loadedTeams[key].playerIsGoalie19)
		setIsTaxi19(loadedTeams[key].playerIsTaxi19)
		//
		setName20(loadedTeams[key].name20)
		setNumber20(loadedTeams[key].number20)
		setBY20(loadedTeams[key].BY20)
		setIsGoalie20(loadedTeams[key].playerIsGoalie20)
		setIsTaxi20(loadedTeams[key].playerIsTaxi20)
		//
		setName21(loadedTeams[key].name21)
		setNumber21(loadedTeams[key].number21)
		setBY21(loadedTeams[key].BY21)
		setIsGoalie21(loadedTeams[key].playerIsGoalie21)
		setIsTaxi21(loadedTeams[key].playerIsTaxi21)
		//
		setName22(loadedTeams[key].name22)
		setNumber22(loadedTeams[key].number22)
		setBY22(loadedTeams[key].BY22)
		setIsGoalie22(loadedTeams[key].playerIsGoalie22)
		setIsTaxi22(loadedTeams[key].playerIsTaxi22)
		//
		setName23(loadedTeams[key].name23)
		setNumber23(loadedTeams[key].number23)
		setBY23(loadedTeams[key].BY23)
		setIsGoalie23(loadedTeams[key].playerIsGoalie23)
		setIsTaxi23(loadedTeams[key].playerIsTaxi23)
		//
		setName24(loadedTeams[key].name24)
		setNumber24(loadedTeams[key].number24)
		setBY24(loadedTeams[key].BY24)
		setIsGoalie24(loadedTeams[key].playerIsGoalie24)
		setIsTaxi24(loadedTeams[key].playerIsTaxi24)
		//
		setName25(loadedTeams[key].name25)
		setNumber25(loadedTeams[key].number25)
		setBY25(loadedTeams[key].BY25)
		setIsGoalie25(loadedTeams[key].playerIsGoalie25)
		setIsTaxi25(loadedTeams[key].playerIsTaxi25)
		//
		setName26(loadedTeams[key].name26)
		setNumber26(loadedTeams[key].number26)
		setBY26(loadedTeams[key].BY26)
		setIsGoalie26(loadedTeams[key].playerIsGoalie26)
		setIsTaxi26(loadedTeams[key].playerIsTaxi26)
		//
		setName27(loadedTeams[key].name27)
		setNumber27(loadedTeams[key].number27)
		setBY27(loadedTeams[key].BY27)
		setIsGoalie27(loadedTeams[key].playerIsGoalie27)
		setIsTaxi27(loadedTeams[key].playerIsTaxi27)
		//
		setName28(loadedTeams[key].name28)
		setNumber28(loadedTeams[key].number28)
		setBY28(loadedTeams[key].BY28)
		setIsGoalie28(loadedTeams[key].playerIsGoalie28)
		setIsTaxi28(loadedTeams[key].playerIsTaxi28)
		//
		setName29(loadedTeams[key].name29)
		setNumber29(loadedTeams[key].number29)
		setBY29(loadedTeams[key].BY29)
		setIsGoalie29(loadedTeams[key].playerIsGoalie29)
		setIsTaxi29(loadedTeams[key].playerIsTaxi29)
		//
		setName30(loadedTeams[key].name30)
		setNumber30(loadedTeams[key].number30)
		setBY30(loadedTeams[key].BY30)
		setIsGoalie30(loadedTeams[key].playerIsGoalie30)
		setIsTaxi30(loadedTeams[key].playerIsTaxi30)
		//
		setIsActive1(loadedTeams[key].playerIsActive1)
		setIsActive2(loadedTeams[key].playerIsActive2)
		setIsActive3(loadedTeams[key].playerIsActive3)
		setIsActive4(loadedTeams[key].playerIsActive4)
		setIsActive5(loadedTeams[key].playerIsActive5)
		setIsActive6(loadedTeams[key].playerIsActive6)
		setIsActive7(loadedTeams[key].playerIsActive7)
		setIsActive8(loadedTeams[key].playerIsActive8)
		setIsActive9(loadedTeams[key].playerIsActive9)
		setIsActive10(loadedTeams[key].playerIsActive10)
		setIsActive11(loadedTeams[key].playerIsActive11)
		setIsActive12(loadedTeams[key].playerIsActive12)
		setIsActive13(loadedTeams[key].playerIsActive13)
		setIsActive14(loadedTeams[key].playerIsActive14)
		setIsActive15(loadedTeams[key].playerIsActive15)
		setIsActive16(loadedTeams[key].playerIsActive16)
		setIsActive17(loadedTeams[key].playerIsActive17)
		setIsActive18(loadedTeams[key].playerIsActive18)
		setIsActive19(loadedTeams[key].playerIsActive19)
		setIsActive20(loadedTeams[key].playerIsActive20)
		setIsActive21(loadedTeams[key].playerIsActive21)
		setIsActive22(loadedTeams[key].playerIsActive22)
		setIsActive23(loadedTeams[key].playerIsActive23)
		setIsActive24(loadedTeams[key].playerIsActive24)
		setIsActive25(loadedTeams[key].playerIsActive25)
		setIsActive26(loadedTeams[key].playerIsActive26)
		setIsActive27(loadedTeams[key].playerIsActive27)
		setIsActive28(loadedTeams[key].playerIsActive28)
		setIsActive29(loadedTeams[key].playerIsActive29)
		setIsActive30(loadedTeams[key].playerIsActive30)
		setRosterModal(true)
	}
	//
	const cancelRosterHandler = () => {
		setRosterModal(false)
	}
	//
	//
	let totalActivePlayers = 0
	if (loadedTeams) {
		if (isActive1) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive2) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive3) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive4) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive5) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive6) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive7) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive8) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive9) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive10) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive11) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive12) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive13) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive14) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive15) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive16) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive17) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive18) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive19) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive20) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive21) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive22) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive23) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive24) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive25) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive26) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive27) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive28) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive29) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive30) {
			totalActivePlayers = totalActivePlayers + 1
		}
	}
	//
	//
	//******************************************************** */
	//
	//     'Move to archive' warning
	//
	//******************************************************** */
	const showMoveToArchiveHandler = (e) => {
		const value = e.target.id
		console.log(loadedTeams[value]._id)
		setTeamId(loadedTeams[value]._id)
		setShowConfirmMoveToArchiveModal(true)
	}

	const cancelMoveToArchiveHandler = () => {
		setShowConfirmMoveToArchiveModal(false)
	}

	const confirmMoveToArchiveHandler = async () => {
		setShowConfirmMoveToArchiveModal(false)
		try {
			await sendRequest(
				`${process.env.REACT_APP_BACKEND_URL}/admin/${teamId}/archiveCurrentToggleTeam`,
				'PATCH'
			)
		} catch (err) {}
		setTeamToArchive(true)
	}
	//
	//
	//
	//
	//
	//
	//
	//
	//console.log('filteredLoadedTeams: ' + JSON.stringify(filteredLoadedTeams))
	return (
		<React.Fragment>
			<ModalEmail
				show={emailModal}
				onCancel={cancelEmailHandler}
				header='Send an Email'
				footerClass='place-item__modal-actions'
				footer={
					<React.Fragment>
						<div
							className='container'
							style={{ marginTop: '0.5rem', width: '80%' }}
						>
							<form
								method='post'
								enctype='text/plain'
								id='emailForm'
								style={{ height: '80%' }}
							>
								<label>Subject: </label>
								<input
									type='text'
									name='subject'
									className='form-control emailInput'
									defaultValue={'New Message from PBHL'}
									required
								></input>

								<br></br>
								<br></br>

								<label>From: </label>
								<input
									type='text'
									name='from'
									className='form-control emailInput'
									defaultValue='Pennsylvania Ball Hockey League'
								></input>

								<br></br>
								<br></br>

								<label>To: </label>
								<input
									type='text'
									name='to'
									className='form-control emailInput'
									defaultValue={uniqueEmailAddresses && uniqueEmailAddresses}
								></input>

								<br></br>
								<br></br>

								<label>Message</label>
								<textarea
									name='message'
									rows='6'
									className='form-control emailInput'
								/>
								<button class='buttonButton2' type='submit' onClick={sendEmail}>
									Send
								</button>
								<button
									className='buttonButton2'
									style={{ marginLeft: '2rem' }}
									onClick={cancelEmailHandler}
								>
									CANCEL
								</button>
							</form>
						</div>
						<br></br>
					</React.Fragment>
				}
			></ModalEmail>
			<ModalEmail
				show={rosterModal}
				onCancel={cancelRosterHandler}
				header={teamName}
				footerClass='place-item__modal-actions'
				footer={
					<React.Fragment>
						<div
							className='container'
							style={{ marginTop: '0.5rem', width: '80%' }}
						>
							<div
								style={{
									textAlign: 'center',
									fontWeight: 'bolder',
									marginBottom: '1.5rem',
								}}
							>
								Date of last edit: {dateOfLastEdit}
							</div>
							<h3>Total Active Players: {totalActivePlayers}</h3>
							<table className='rosterModalTable'>
								<thead className='tableHeader2'>
									<tr>
										<th>Name</th>
										<th>#</th>
										<th></th>
										<th style={{ textAlign: 'center' }}>Birth Year</th>
										<th></th>
										<th></th>
										<th></th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td style={{ textAlign: 'left' }}>{name1}</td>
										<td style={{ textAlign: 'left' }}>{number1}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY1}</td>
										{isGoalie1 && <td style={{ textAlign: 'left' }}>Goalie</td>}
										<td></td>
										{isTaxi1 && (
											<td style={{ textAlign: 'left' }}>Taxi Squad</td>
										)}
										{name1 && !isActive1 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>{name2}</td>
										<td style={{ textAlign: 'left' }}>{number2}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY2}</td>
										{isGoalie2 && <td style={{ textAlign: 'left' }}>Goalie</td>}
										<td></td>
										{isTaxi2 && (
											<td style={{ textAlign: 'left' }}>Taxi Squad</td>
										)}
										{name2 && !isActive2 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>{name3}</td>
										<td style={{ textAlign: 'left' }}>{number3}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY3}</td>
										{isGoalie3 && <td style={{ textAlign: 'left' }}>Goalie</td>}
										<td></td>
										{isTaxi3 && (
											<td style={{ textAlign: 'left' }}>Taxi Squad</td>
										)}
										{name3 && !isActive3 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>{name4}</td>
										<td style={{ textAlign: 'left' }}>{number4}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY4}</td>
										{isGoalie4 && <td style={{ textAlign: 'left' }}>Goalie</td>}
										<td></td>
										{isTaxi4 && (
											<td style={{ textAlign: 'left' }}>Taxi Squad</td>
										)}
										{name4 && !isActive4 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>{name5}</td>
										<td style={{ textAlign: 'left' }}>{number5}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY5}</td>
										{isGoalie5 && <td style={{ textAlign: 'left' }}>Goalie</td>}
										<td></td>
										{isTaxi5 && (
											<td style={{ textAlign: 'left' }}>Taxi Squad</td>
										)}
										{name5 && !isActive5 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>{name6}</td>
										<td style={{ textAlign: 'left' }}>{number6}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY6}</td>
										{isGoalie6 && <td style={{ textAlign: 'left' }}>Goalie</td>}
										<td></td>
										{isTaxi6 && (
											<td style={{ textAlign: 'left' }}>Taxi Squad</td>
										)}
										{name6 && !isActive6 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>{name7}</td>
										<td style={{ textAlign: 'left' }}>{number7}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY7}</td>
										{isGoalie7 && <td style={{ textAlign: 'left' }}>Goalie</td>}
										<td></td>
										{isTaxi7 && (
											<td style={{ textAlign: 'left' }}>Taxi Squad</td>
										)}
										{name7 && !isActive7 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>{name8}</td>
										<td style={{ textAlign: 'left' }}>{number8}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY8}</td>
										{isGoalie8 && <td style={{ textAlign: 'left' }}>Goalie</td>}
										<td></td>
										{isTaxi8 && (
											<td style={{ textAlign: 'left' }}>Taxi Squad</td>
										)}
										{name8 && !isActive8 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>{name9}</td>
										<td style={{ textAlign: 'left' }}>{number9}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY9}</td>
										{isGoalie9 && <td style={{ textAlign: 'left' }}>Goalie</td>}
										<td></td>
										{isTaxi9 && (
											<td style={{ textAlign: 'left' }}>Taxi Squad</td>
										)}
										{name9 && !isActive9 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>{name10}</td>
										<td style={{ textAlign: 'left' }}>{number10}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY10}</td>
										{isGoalie10 && (
											<td style={{ textAlign: 'left' }}>Goalie</td>
										)}
										<td></td>
										{isTaxi10 && (
											<td style={{ textAlign: 'left' }}>Taxi Squad</td>
										)}
										{name10 && !isActive10 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>{name11}</td>
										<td style={{ textAlign: 'left' }}>{number11}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY11}</td>
										{isGoalie11 && (
											<td style={{ textAlign: 'left' }}>Goalie</td>
										)}
										<td></td>
										{isTaxi11 && (
											<td style={{ textAlign: 'left' }}>Taxi Squad</td>
										)}
										{name11 && !isActive11 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>{name12}</td>
										<td style={{ textAlign: 'left' }}>{number12}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY12}</td>
										{isGoalie12 && (
											<td style={{ textAlign: 'left' }}>Goalie</td>
										)}
										<td></td>
										{isTaxi12 && (
											<td style={{ textAlign: 'left' }}>Taxi Squad</td>
										)}
										{name12 && !isActive12 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>{name13}</td>
										<td style={{ textAlign: 'left' }}>{number13}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY13}</td>
										{isGoalie13 && (
											<td style={{ textAlign: 'left' }}>Goalie</td>
										)}
										<td></td>
										{isTaxi13 && (
											<td style={{ textAlign: 'left' }}>Taxi Squad</td>
										)}
										{name13 && !isActive13 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>{name14}</td>
										<td style={{ textAlign: 'left' }}>{number14}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY14}</td>
										{isGoalie14 && (
											<td style={{ textAlign: 'left' }}>Goalie</td>
										)}
										<td></td>
										{isTaxi14 && (
											<td style={{ textAlign: 'left' }}>Taxi Squad</td>
										)}
										{name14 && !isActive14 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>{name15}</td>
										<td style={{ textAlign: 'left' }}>{number15}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY15}</td>
										{isGoalie15 && (
											<td style={{ textAlign: 'left' }}>Goalie</td>
										)}
										{name15 && !isActive15 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
										<td></td>
										{isTaxi15 && (
											<td style={{ textAlign: 'left' }}>Taxi Squad</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>{name16}</td>
										<td style={{ textAlign: 'left' }}>{number16}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY16}</td>
										{isGoalie16 && (
											<td style={{ textAlign: 'left' }}>Goalie</td>
										)}
										<td></td>
										{isTaxi16 && (
											<td style={{ textAlign: 'left' }}>Taxi Squad</td>
										)}
										{name16 && !isActive16 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>{name17}</td>
										<td style={{ textAlign: 'left' }}>{number17}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY17}</td>
										{isGoalie17 && (
											<td style={{ textAlign: 'left' }}>Goalie</td>
										)}
										<td></td>
										{isTaxi17 && (
											<td style={{ textAlign: 'left' }}>Taxi Squad</td>
										)}
										{name17 && !isActive17 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>{name18}</td>
										<td style={{ textAlign: 'left' }}>{number18}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY18}</td>
										{isGoalie18 && (
											<td style={{ textAlign: 'left' }}>Goalie</td>
										)}
										<td></td>
										{isTaxi18 && (
											<td style={{ textAlign: 'left' }}>Taxi Squad</td>
										)}
										{name18 && !isActive18 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>{name19}</td>
										<td style={{ textAlign: 'left' }}>{number19}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY19}</td>
										{isGoalie19 && (
											<td style={{ textAlign: 'left' }}>Goalie</td>
										)}
										<td></td>
										{isTaxi19 && (
											<td style={{ textAlign: 'left' }}>Taxi Squad</td>
										)}
										{name19 && !isActive19 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>{name20}</td>
										<td style={{ textAlign: 'left' }}>{number20}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY20}</td>
										{isGoalie20 && (
											<td style={{ textAlign: 'left' }}>Goalie</td>
										)}
										<td></td>
										{isTaxi20 && (
											<td style={{ textAlign: 'left' }}>Taxi Squad</td>
										)}
										{name20 && !isActive20 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>{name21}</td>
										<td style={{ textAlign: 'left' }}>{number21}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY21}</td>
										{isGoalie21 && (
											<td style={{ textAlign: 'left' }}>Goalie</td>
										)}
										<td></td>
										{isTaxi21 && (
											<td style={{ textAlign: 'left' }}>Taxi Squad</td>
										)}
										{name21 && !isActive21 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>{name22}</td>
										<td style={{ textAlign: 'left' }}>{number22}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY22}</td>
										{isGoalie22 && (
											<td style={{ textAlign: 'left' }}>Goalie</td>
										)}
										<td></td>
										{isTaxi22 && (
											<td style={{ textAlign: 'left' }}>Taxi Squad</td>
										)}
										{name22 && !isActive22 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>{name23}</td>
										<td style={{ textAlign: 'left' }}>{number23}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY23}</td>
										{isGoalie23 && (
											<td style={{ textAlign: 'left' }}>Goalie</td>
										)}
										<td></td>
										{isTaxi23 && (
											<td style={{ textAlign: 'left' }}>Taxi Squad</td>
										)}
										{name23 && !isActive23 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>{name24}</td>
										<td style={{ textAlign: 'left' }}>{number24}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY24}</td>
										{isGoalie24 && (
											<td style={{ textAlign: 'left' }}>Goalie</td>
										)}
										<td></td>
										{isTaxi24 && (
											<td style={{ textAlign: 'left' }}>Taxi Squad</td>
										)}
										{name24 && !isActive24 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>{name25}</td>
										<td style={{ textAlign: 'left' }}>{number25}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY25}</td>
										{isGoalie25 && (
											<td style={{ textAlign: 'left' }}>Goalie</td>
										)}
										<td></td>
										{isTaxi25 && (
											<td style={{ textAlign: 'left' }}>Taxi Squad</td>
										)}
										{name25 && !isActive25 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>{name26}</td>
										<td style={{ textAlign: 'left' }}>{number26}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY26}</td>
										{isGoalie26 && (
											<td style={{ textAlign: 'left' }}>Goalie</td>
										)}
										<td></td>
										{isTaxi26 && (
											<td style={{ textAlign: 'left' }}>Taxi Squad</td>
										)}
										{name26 && !isActive26 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>{name27}</td>
										<td style={{ textAlign: 'left' }}>{number27}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY27}</td>
										{isGoalie27 && (
											<td style={{ textAlign: 'left' }}>Goalie</td>
										)}
										<td></td>
										{isTaxi27 && (
											<td style={{ textAlign: 'left' }}>Taxi Squad</td>
										)}
										{name27 && !isActive27 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>{name28}</td>
										<td style={{ textAlign: 'left' }}>{number28}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY28}</td>
										{isGoalie28 && (
											<td style={{ textAlign: 'left' }}>Goalie</td>
										)}
										<td></td>
										{isTaxi28 && (
											<td style={{ textAlign: 'left' }}>Taxi Squad</td>
										)}
										{name28 && !isActive28 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>{name29}</td>
										<td style={{ textAlign: 'left' }}>{number29}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY29}</td>
										{isGoalie29 && (
											<td style={{ textAlign: 'left' }}>Goalie</td>
										)}
										<td></td>
										{isTaxi29 && (
											<td style={{ textAlign: 'left' }}>Taxi Squad</td>
										)}
										{name29 && !isActive29 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>{name30}</td>
										<td style={{ textAlign: 'left' }}>{number30}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY30}</td>
										{isGoalie30 && (
											<td style={{ textAlign: 'left' }}>Goalie</td>
										)}
										<td></td>
										{isTaxi30 && (
											<td style={{ textAlign: 'left' }}>Taxi Squad</td>
										)}
										{name30 && !isActive30 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
									</tr>
								</tbody>
							</table>
						</div>
						<br></br>
					</React.Fragment>
				}
			></ModalEmail>
			<Modal
				show={showConfirmMoveToArchiveModal}
				onCancel={cancelMoveToArchiveHandler}
				header='Are you sure?'
				footerClass='place-item__modal-actions'
				footer={
					<React.Fragment>
						<p className='modalText'>
							Are you sure you wish to Archive this team?
						</p>
						<br></br>
						<button
							className='cancelDeleteButtons buttonButton'
							inverse
							style={{ float: 'right' }}
							onClick={cancelMoveToArchiveHandler}
						>
							CANCEL
						</button>
						<button
							className='cancelDeleteButtons buttonButton'
							danger
							onClick={confirmMoveToArchiveHandler}
						>
							ARCHIVE THIS TEAM
						</button>
					</React.Fragment>
				}
			></Modal>
			<ErrorModal error={error} onClear={errorHandler} />
			{isLoading && (
				<div className='center'>
					<LoadingSpinner />
				</div>
			)}
			{!isLoading && loadedTeams && (
				<div className='RegLeagues' style={{ marginBottom: '5rem' }}>
					<AdminNavigation />
					<h1>Registered Teams</h1>
					<div style={{ marginBottom: '1.5rem' }}>
						<div className='row'>
							<div className='LTVinstructions'>Filter by Year: </div>
						</div>
						{!isLoading && loadedTeams && (
							<div className='row'>
								<div className='col'></div>
								<div className='col-sm'>
									{/* <form className='form-control-lg'> */}
									<form className='dropdownForm'>
										<select
											id='year'
											type='text'
											tabIndex='9'
											placeholder='Year'
											className='scheduleselector'
											onChange={handleFilterByYear}
										>
											<option value='2024'>2024</option>
											<option value='2025'>2025</option>
											<option value='2026'>2026</option>
											<option value='2027'>2027</option>
											<option value='2028'>2027</option>
											<option value='2029'>2029</option>
											<option value='2030'>2030</option>
											<option value='2031'>2031</option>
											<option value='2032'>2032</option>
											<option value='2033'>2033</option>
											<option value='2034'>2034</option>
											<option value='2034'>2035</option>
										</select>
									</form>
								</div>
								<div className='col'></div>
							</div>
						)}
					</div>
					<table id='regLeagues'>
						<thead>
							<tr>
								<th>Year</th>
								<th>Organization</th>
								<th>Team Name</th>
								<th>Head Coach</th>
								<th>Assistant Coaches</th>
								<th></th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{filteredLoadedTeams &&
								filteredLoadedTeams.map((team, key) => {
									return (
										<tr key={key}>
											<td>{team.year}</td>
											<td>{team.organization}</td>
											<td>{team.team}</td>
											<td>{team.headCoach}</td>
											<td>{team.assistantCoaches}</td>
											<td>
												<AdminButton
													type='button'
													onClick={(e) => showRosterModal(e, key)}
													exact
													style={{
														color: 'white',
														textDecoration: 'none',
														backgroundColor: '#e0e0e0',
													}}
												>
													View Roster
												</AdminButton>
											</td>
										</tr>
									)
								})}
							{!filteredLoadedTeams &&
								loadedTeams.map((team, key) => {
									return (
										<tr key={key}>
											<td>{team.year}</td>
											<td>{team.organization}</td>
											<td>{team.team}</td>
											<td>{team.headCoach}</td>
											<td>{team.assistantCoaches}</td>
											<td>
												<AdminButton
													type='button'
													onClick={(e) => showRosterModal(e, key)}
													exact
													style={{
														color: 'white',
														textDecoration: 'none',
														backgroundColor: '#e0e0e0',
													}}
												>
													View Roster
												</AdminButton>
											</td>
											<td>
												<button
													style={{
														backgroundColor: '#92562e',
														border: '1px solid #92562e',
														borderRadius: '4px',
														color: 'white',
														padding: '0.5rem 1.5rem',
													}}
													className='buttonButton button2'
													id={key}
													onClick={(e) => showMoveToArchiveHandler(e)}
												>
													Archive Team
												</button>
											</td>
										</tr>
									)
								})}
						</tbody>
					</table>

					<AdminButton
						type='button'
						onClick={(e) => showEmailModal(e)}
						exact
						style={{
							color: 'white',
							textDecoration: 'none',
							backgroundColor: '#e0e0e0',
						}}
					>
						Send an Email
					</AdminButton>
				</div>
			)}
		</React.Fragment>
	)
}

export default AdminRegisteredTeams
