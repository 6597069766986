import React from 'react'
import PasswordModal from './PasswordModal'
import Button from './Button'

const PasswordErrorModal = (props) => {
	return (
		<PasswordModal
			onCancel={props.onClear}
			header='An Error Occurred!'
			show={!!props.error}
			footer={<Button onClick={props.onClear}>Okay</Button>}
		>
			<p>{props.error}</p>
		</PasswordModal>
	)
}

export default PasswordErrorModal
