import React, { useState, useEffect } from 'react'
import '../games/AddGamesForm.css'
import AdminNavigation from './AdminNavigation'
import { useNavigate } from 'react-router-dom'
import AdminButton from '../../shared/components/UIElements/AdminButton'
import ErrorModal from '../../shared/components/UIElements/ErrorModal'
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner'
import Input from '../../shared/components/FormElements/Input'
import Button from '../../shared/components/UIElements/Button'
import { VALIDATOR_REQUIRE } from '../../shared/util/validators'
import { useForm } from '../../shared/hooks/form-hook'

const AdminNewSuspension = () => {
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState()
	const [playerName, setPlayerName] = useState('')
	const [team, setTeam] = useState('')
	const [reason, setReason] = useState('')
	const [numberOfGames, setNumberOfGames] = useState()
	const [served, setServed] = useState()
	const [appealed, setAppealed] = useState()

	let navigate = useNavigate()
	//
	//
	//
	//
	const [formState, inputHandler] = useForm(
		{
			playerName: {
				value: '',
				isValid: false,
			},
			team: {
				value: '',
				isValid: false,
			},
			reason: {
				value: '',
				isValid: false,
			},
		},
		false
	)
	//
	//
	//
	const errorHandler = () => {
		setError(null)
	}
	//
	//
	//
	const getNumberOfGames = (event) => {
		setNumberOfGames(event.target.value)
	}
	const servedCheckbox = (e) => {
		setServed(true)
	}

	const appealedCheckbox = (e) => {
		setAppealed(true)
	}
	//
	//
	const suspensionSubmitHandler = async (event) => {
		event.preventDefault()

		try {
			setIsLoading(true)

			const response = await fetch(
				process.env.REACT_APP_BACKEND_URL + '/admin/createSuspension',
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						playerName: formState.inputs.playerName.value,
						team: formState.inputs.team.value,
						reason: formState.inputs.reason.value,
						numberOfGames,
						served,
						appealed,
					}),
				}
			)
			const responseData = await response.json()
			if (!response.ok) {
				throw new Error(responseData.message)
			}
			setIsLoading(false)
			navigate('/admin/suspended')
			//navigate('/admin/leagues/current')
		} catch (err) {
			setIsLoading(false)
			setError(
				err.message ||
					'Something went wrong with creating a new suspended player'
			)
		}
	}

	return (
		<React.Fragment>
			<ErrorModal error={error} onClear={errorHandler} />
			{isLoading && <LoadingSpinner asOverlay />}
			{!isLoading && (
				<div className='AddEvents'>
					<AdminNavigation />
					<h1>New Suspension</h1>
					<form className='suspension-form' onSubmit={suspensionSubmitHandler}>
						<Input
							id='playerName'
							element='input'
							type='text'
							label='Player Name'
							validators={[VALIDATOR_REQUIRE()]}
							errorText='Please enter a player name'
							onInput={inputHandler}
						/>
						<Input
							id='team'
							element='input'
							type='text'
							label='Team'
							validators={[VALIDATOR_REQUIRE()]}
							errorText='Please enter players team'
							onInput={inputHandler}
						/>
						<Input
							id='reason'
							element='input'
							type='text'
							label='Reason'
							validators={[VALIDATOR_REQUIRE()]}
							onInput={inputHandler}
						/>
						<td style={{ fontWeight: 'bolder', textAlign: 'left' }}>
							Number of Games:{' '}
						</td>
						<td>
							<input
								id='numberOfGames'
								type='number'
								min='0'
								max='99'
								onChange={getNumberOfGames}
							/>
						</td>
						<tr>
							<td style={{ fontWeight: 'bolder', textAlign: 'left' }}>
								Served?
							</td>
							<td>
								<input id='served' type='checkbox' onChange={servedCheckbox} />
							</td>
						</tr>
						<tr>
							<td style={{ fontWeight: 'bolder', textAlign: 'left' }}>
								Successfully Appealed?
							</td>
							<td>
								<input
									id='appealed'
									type='checkbox'
									onChange={appealedCheckbox}
								/>
							</td>
						</tr>
						<Button type='submit' disabled={!formState.isValid}>
							ADD SUSPENSION
						</Button>
					</form>
				</div>
			)}
		</React.Fragment>
	)
}

export default AdminNewSuspension
